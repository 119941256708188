export default function PieceErrorNull() {
  return (
    <section className="tableWrap">
      <h3 className="blind">table</h3>
      <div className="paddingBox commonBox">
        <div className="nullBox">데이터가 없습니다.</div>
      </div>
    </section>
  );
}
