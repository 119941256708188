export const setPage = (page) => ({
  type: "servicePageData",
  payload: page,
});

export const setListPage = (page) => ({
  type: "serviceListPageData",
  payload: page,
});

export const setListFilter = (page) => ({
  type: "serviceListFilterData",
  payload: page,
});
