/* string 선언 */
export const TOKEN = "token";
export const UID = "uid";
export const USERID = "userId";
export const ALLKEYWORD = "allKeyword";
export const WRITER = "writer";

// 안심번호
export const FIRST_SAFENUM_1000 = 1000;
export const SECOND_SAFENUM_10000 = 10000;
