export function serviceImgData(payload) {
  return {
    type: "serviceImgData",
    payload: payload,
  };
}
export function serviceimgsData(payload) {
  return {
    type: "serviceimgsData",
    payload: payload,
  };
}
export function servicemulTiImgsData(payload) {
  return {
    type: "servicemulTiImgsData",
    payload: payload,
  };
}
