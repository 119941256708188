// 공사콕 앱 관리 > 공사콕 이벤트 관리 리스트

import { useForm } from "react-hook-form";
import { useState } from "react";
import { Link } from "react-router-dom";

import * as API from "../../service/api";
import * as CUS from "../../service/customHook";
import * as APIURL from "../../service/string/apiUrl";

import LayoutTopButton from "../../components/layout/LayoutTopButton";
import ComponentErrorNull from "../../components/piece/PieceErrorNull";
import PaginationButton from "../../components/services/ServicesPaginationButton";

export default function ListEvent() {
  const { watch } = useForm({
    defaultValues: {
      _category: "wzEvent",
    },
  });

  // 데이터 ------------------------------------------------------------------------
  // 목록 데이터
  const [wzEvent, setWzEvent] = useState([]);

  // pagination 버튼 관련 ------------------------------------------------------------------------
  // listPage: 컨텐츠 총 개수 / page:전체 페이지 수 & 현재 페이지
  const [listPage, setListPage] = useState({});
  const [page, setPage] = useState({ getPage: 0, activePage: 1 });
  const [showUseFlagZero, setShowUseFlagZero] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const [buttonText, setButtonText] = useState("게시글 전체보기");

  const toggleVisibility = () => {
    setShowUseFlagZero((prev) => !prev);
    setButtonText((prev) =>
      prev === "게시글 전체보기" ? "비공개 숨김" : "게시글 전체보기"
    );
    setButtonClicked((prev) => !prev);
  };

  // 카테고리 확인하여 데이터 요청
  CUS.useCleanupEffect(() => {
    API.servicesPostData(APIURL.urlContentList, {
      // category: watch("_category") || "wzEvent",
      category: "wzEvent",
      offset: 0,
      size: 15,
    }).then((res) => {
      setWzEvent(res.data);
      setListPage(res.page);
    });
  }, [watch("_category") || page.getPage]);

  return (
    <>
      <ul className="tableTopWrap">
        <LayoutTopButton url={`set`} text="추가" />
      </ul>
      <section className="tableWrap">
        <h3 className="blind">공사콕 이벤트 목록</h3>
        <div className="paddingBox commonBox">
          {/* <div className="filterWrap">
            <label className="listSearchRadioLabel" htmlFor="wzEvent">
              <input
                type="radio"
                checked={watch("_category") == "wzEvent"}
                value="wzEvent"
                id="wzEvent"
                {...register("_category")}
              />
              <span>와짱 이벤트</span>
            </label>
            
            <label className="listSearchRadioLabel" htmlFor="businessEvent">
              <input
                type="radio"
                checked={watch("_category") == "businessEvent"}
                value="businessEvent"
                id="businessEvent"
                {...register("_category")}
              />
              <span>고객 ( 사용자 ) 이벤트</span>
            </label>
          </div> */}

          {(wzEvent == [] && wzEvent.length == 0) || wzEvent === null ? (
            <ComponentErrorNull />
          ) : (
            <>
              <div className="hideButtonContainer">
                <button
                  className={`hideButton ${buttonClicked ? "clicked" : ""}`}
                  onClick={toggleVisibility}
                >
                  {buttonText}
                </button>
              </div>
              <table className="commonTable">
                <thead>
                  <tr>
                    <th style={{ width: "auto" }}>내용</th>
                    <th style={{ width: "150px" }}>날짜</th>
                  </tr>
                </thead>
                <tbody className="commonTable">
                  {wzEvent &&
                    wzEvent
                      .filter((item) =>
                        showUseFlagZero ? true : item.useFlag !== 0
                      )
                      .map((item) => (
                        <tr
                          key={item.contid}
                          style={{
                            height: "5.25rem",
                            backgroundColor:
                              item.useFlag === 0 ? "#e0e0e0" : "white",
                            color: item.useFlag === 0 ? "#aeaeae" : "black",
                          }}
                        >
                          <td className="tableContentWrap">
                            <Link
                              to={`${item.contid}/set`}
                              className="Link"
                              style={{ paddingLeft: "30px" }}
                            >
                              <div
                                className="contentInnerTag"
                                dangerouslySetInnerHTML={{
                                  __html: item.contentString,
                                }}
                              />
                              <em>{item.contentDetail}</em>
                            </Link>
                          </td>
                          <td>{item.createTime.slice(0, 10)}</td>
                        </tr>
                      ))}
                </tbody>
              </table>
              <PaginationButton
                itemCount={15}
                listPage={listPage}
                page={page}
                setPage={setPage}
              />
            </>
          )}
        </div>
      </section>
    </>
  );
}
